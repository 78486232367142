export const numberFilterMixin = {
  filters: {
    numberFilter_threeDecimals: function (value) {
      if (!value) {
        return null;
      }
      if (typeof value != 'number') {
        return null;
      }
      return value.toFixed(3);
    },
    numberFilter_twoDecimals: function (value) {
      if (!value) {
        return null;
      }
      if (typeof value != 'number') {
        return null;
      }
      return value.toFixed(2);
    },
    numberFilter_oneDecimals: function (value) {
      if (!value) {
        return null;
      }
      if (typeof value != 'number') {
        return null;
      }
      return value.toFixed(1);
    },
    numberFilter_noDecimals: function (value) {
      if (!value) {
        return null;
      }
      return Math.round(value);
    }
  },
  methods: {
    numberFilter_threeDecimals (value) {
      if (!value) {
        return null;
      }
      if (typeof value != 'number') {
        return null;
      }
      return value.toFixed(3);
    },
    numberFilter_twoDecimals (value) {
      if (!value) {
        return null;
      }
      if (typeof value != 'number') {
        return null;
      }
      return value.toFixed(2);
    },
    numberFilter_oneDecimals (value) {
      if (!value) {
        return null;
      }
      if (typeof value != 'number') {
        return null;
      }
      return value.toFixed(1);
    },
    numberFilter_noDecimals (value) {
      if (!value) {
        return null;
      }
      return Math.round(value);
    }
  }
}