var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Portlet',{staticClass:"videoPublisher",attrs:{"title":_vm.$t('videoPublisherComp.videoPublisher'),"icon":"box"}},[_c('template',{slot:"buttons"},[_c('a',{staticClass:"m-portlet__nav-link m-portlet__nav-link--icon",attrs:{"href":"#","m-portlet-tool":"reload"},on:{"click":function($event){$event.preventDefault();return _vm.refreshVideoPublisher()}}},[_c('font-awesome-icon',{class:[{ 'fa-spin' : _vm.loadingSessions}],attrs:{"icon":"sync-alt"}})],1),_c('toggle-button',{staticClass:"ml-3 mt-2",attrs:{"labels":{ checked: 'only processed', unchecked: 'all' },"width":133,"height":30,"font-size":12},model:{value:(_vm.onlyProcessed),callback:function ($$v) {_vm.onlyProcessed=$$v},expression:"onlyProcessed"}}),_c('button',{staticClass:"btn btn-sm btn-secondary ml-3",on:{"click":function($event){return _vm.loadAllSessionsToCache()}}},[_c('span',[_vm._v("Load Sessions to Cache")])]),_c('button',{staticClass:"btn btn-sm btn-secondary",on:{"click":function($event){return _vm.showReprocessSidebar()}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"sync-alt"}}),_vm._v(_vm._s(_vm.$t('videoPublisherComp.reprocess'))+" ")],1),_c('button',{staticClass:"btn btn-sm btn-secondary",on:{"click":function($event){return _vm.openConfirmModal()}}},[_c('font-awesome-icon',{staticClass:"gray mr-2",attrs:{"icon":"redo-alt"}}),_c('span',[_vm._v("Restart VideoPostProcessing")])],1)],1),_c('ul',{staticClass:"nav nav-tabs"},[_c('li',{staticClass:"nav-item"},[_c('a',{class:[
          'nav-link',
          _vm.showTab === 'sessions' ? 'active' : '' ],on:{"click":function($event){_vm.showTab = 'sessions'}}},[_vm._v(_vm._s(_vm.$t('sessions')))])]),_c('li',{staticClass:"nav-item"},[_c('a',{class:[
          'nav-link',
          _vm.showTab === 'uploads' ? 'active' : '' ],on:{"click":function($event){_vm.showTab = 'uploads'}}},[_vm._v(_vm._s(_vm.$t('uploads')))])])]),_c('div',{staticClass:"tab-content"},[(_vm.showTab === 'sessions')?_c('div',[_c('LoadingPlaceholder',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingSessions),expression:"loadingSessions"}]}),[_c('Grid',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingSessions),expression:"!loadingSessions"}],ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.computedSessions,"columns":_vm.kgm_responsiveColumns(),"filterable":true,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.takeCount,"skip":_vm.skipCount,"take":_vm.takeCount,"total":_vm.sessionCount,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"createdFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('date-picker',{attrs:{"shortcuts":_vm.createDateShortcuts,"editable":true,"not-after":new Date(),"clearable":false,"ignore-this":{props: props, methods: methods},"range":"","confirm":"","range-separator":"-","type":"date","lang":"en","format":"DD.MM.YYYY HH:mm","value-type":"date","input-class":"form-control mr-2 rounded-right"},on:{"input":function (event) { _vm.createdFilter.dates = [event[0], event[1]]; }},model:{value:(_vm.createdFilter.dates),callback:function ($$v) {_vm.$set(_vm.createdFilter, "dates", $$v)},expression:"createdFilter.dates"}}),(_vm.createdFilter.dates.length > 0)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.createdFilter.dates = []}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()],1)}},{key:"finishedFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('date-picker',{attrs:{"shortcuts":_vm.finishedShortcuts,"editable":true,"not-after":new Date(),"clearable":false,"ignore-this":{props: props, methods: methods},"range":"","confirm":"","range-separator":"-","type":"date","lang":"en","format":"DD.MM.YYYY HH:mm","value-type":"date","input-class":"form-control mr-2 rounded-right"},on:{"input":function (event) { _vm.finishedFilter.dates = [event[0], event[1]]; }},model:{value:(_vm.finishedFilter.dates),callback:function ($$v) {_vm.$set(_vm.finishedFilter, "dates", $$v)},expression:"finishedFilter.dates"}}),(_vm.finishedFilter.dates.length > 0)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.finishedFilter.dates = []}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()],1)}},{key:"checkboxTemplate",fn:function(ref){
        var props = ref.props;
return [_c('td',{class:props.className},[_c('input',{attrs:{"type":"checkbox","disabled":"","readonly":""},domProps:{"checked":_vm.kgm_getNestedValue(props.field, props.dataItem)}})])]}},{key:"dateTemplate",fn:function(ref){
        var props = ref.props;
return [_c('td',{class:props.className},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.dateTime_fromNow(_vm.kgm_getNestedValue(props.field, props.dataItem))),expression:"dateTime_fromNow(kgm_getNestedValue(props.field, props.dataItem))"}]},[_vm._v(_vm._s(_vm.dateTime_dateTime(_vm.kgm_getNestedValue(props.field, props.dataItem))))])])]}},{key:"durationTemplate",fn:function(ref){
        var props = ref.props;
return [_c('td',{class:props.className},[(_vm.kgm_getNestedValue(props.field, props.dataItem))?_c('span',[_vm._v(_vm._s(_vm.numberFilter_twoDecimals(_vm.kgm_getNestedValue(props.field, props.dataItem)))+" seconds")]):_vm._e()])]}},{key:"optionsTemplate",fn:function(ref){
        var props = ref.props;
return [_c('td',{class:props.className},[_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.showSidebar(props.dataItem)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"info-circle"}}),_c('span',[_vm._v("Details")])],1)])])]}}],null,false,4084465254)})]],2):_vm._e(),(_vm.showTab === 'uploads')?_c('div',[_c('VideoPublisherUpload',{ref:"videoPublisherUpload",attrs:{"installation-id":_vm.installationId,"lane-number":_vm.laneNumber}})],1):_vm._e()]),_c('Sidebar',{attrs:{"show-sidebar":_vm.showSidebarBoolean,"sidebar-width":800},on:{"close":_vm.hideSidebar}},[_c('VideoPostProcessing',{ref:"videoPostProcessing",attrs:{"lane-number":_vm.laneNumber},on:{"close":_vm.hideSidebar}})],1),(_vm.showReprocessSidebarBoolean)?_c('Sidebar',{attrs:{"show-sidebar":_vm.showReprocessSidebarBoolean,"sidebar-width":600},on:{"close":_vm.hideReprocessSidebar}},[_c('VideoPublisherReprocess',{attrs:{"installation-id":_vm.installationId,"lane-number":_vm.laneNumber},on:{"refresh":_vm.hideReprocessSidebar}})],1):_vm._e(),_c('SweetModal',{ref:"confirmRestart",staticClass:"overflowHidden",attrs:{"title":"Restart?","icon":"warning","blocking":""}},[_c('p',[_vm._v(_vm._s(_vm.$t('sureToRestart', {'0' : 'VideoPostProcessing'})))]),_c('button',{staticClass:"btn btn-secondary float-left mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.$refs.confirmRestart.close()}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"times"}}),_vm._v(_vm._s(_vm.$t('cancel'))+" ")],1),_c('button',{staticClass:"btn btn-primary float-right mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.restartVideoPostProcessing()}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"redo-alt"}}),_vm._v(_vm._s('Restart')+" ")],1),_c('div',{staticClass:"clearfix"})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }