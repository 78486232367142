<template>
  <!--
    Lists sessions, enables tracking their status and reprocessing them.
  -->
  <Portlet
    :title="$t('videoPublisherComp.videoPublisher')"
    icon="box"
    class="videoPublisher"
  >
    <template slot="buttons">
      <a
        href="#"
        m-portlet-tool="reload"
        class="m-portlet__nav-link m-portlet__nav-link--icon"
        @click.prevent="refreshVideoPublisher()"
      >
        <font-awesome-icon
          :class="[{ 'fa-spin' : loadingSessions}]"
          icon="sync-alt"
        />
      </a>
      <toggle-button
        v-model="onlyProcessed"
        :labels="{ checked: 'only processed', unchecked: 'all' }"
        :width="133"
        :height="30"
        :font-size="12"
        class="ml-3 mt-2"
      />
      <button
        class="btn btn-sm btn-secondary ml-3"
        @click="loadAllSessionsToCache()"
      >
        <span>Load Sessions to Cache</span>
      </button>
      <button
        class="btn btn-sm btn-secondary"
        @click="showReprocessSidebar()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="sync-alt"
        />{{ $t('videoPublisherComp.reprocess') }}
      </button>
      <button
        class="btn btn-sm btn-secondary"
        @click="openConfirmModal()"
      >
        <font-awesome-icon
          class="gray mr-2"
          icon="redo-alt"
        /><span>Restart VideoPostProcessing</span>
      </button>
    </template>

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          :class="[
            'nav-link',
            showTab === 'sessions' ? 'active' : '',
          ]"
          @click="showTab = 'sessions'"
        >{{ $t('sessions') }}</a>
      </li>
      <li class="nav-item">
        <a
          :class="[
            'nav-link',
            showTab === 'uploads' ? 'active' : '',
          ]"
          @click="showTab = 'uploads'"
        >{{ $t('uploads') }}</a>
      </li>
    </ul>
    <div class="tab-content">
      <div
        v-if="showTab === 'sessions'"
      >
        <LoadingPlaceholder v-show="loadingSessions" />
        <template>
          <Grid
            v-show="!loadingSessions"
            :ref="kgm_ref"
            :style="{height: 'auto'}"
            :data-items="computedSessions"
            :columns="kgm_responsiveColumns()"
            :filterable="true"
            :filter="kgm_filter"
            :pageable="kgm_pagable"
            :page-size="takeCount"
            :skip="skipCount"
            :take="takeCount"
            :total="sessionCount"
            selected-field="selected"
            @rowclick="kgm_gridOnRowClick"
            @filterchange="kgm_gridFilterChange"
            @pagechange="gridPageChange"
            @sortchange="kgm_gridSortChange"
          >
            <div
              slot="createdFilter"
              slot-scope="{props, methods}"
              class="input-group"
            >
              <date-picker
                v-model="createdFilter.dates"
                :shortcuts="createDateShortcuts"
                :editable="true"
                :not-after="new Date()"
                :clearable="false"
                :ignore-this="{props, methods}"
                range
                confirm
                range-separator="-"
                type="date"
                lang="en"
                format="DD.MM.YYYY HH:mm"
                value-type="date"
                input-class="form-control mr-2 rounded-right"
                @input="(event) => { createdFilter.dates = [event[0], event[1]]; }"
              />
              <div
                v-if="createdFilter.dates.length > 0"
                class="input-group-append"
              >
                <button
                  class="btn btn-light border rounded"
                  @click="createdFilter.dates = []"
                >
                  <font-awesome-icon
                    class="gray"
                    icon="times"
                  />
                </button>
              </div>
            </div>
            <div
              slot="finishedFilter"
              slot-scope="{props, methods}"
              class="input-group"
            >
              <date-picker
                v-model="finishedFilter.dates"
                :shortcuts="finishedShortcuts"
                :editable="true"
                :not-after="new Date()"
                :clearable="false"
                :ignore-this="{props, methods}"
                range
                confirm
                range-separator="-"
                type="date"
                lang="en"
                format="DD.MM.YYYY HH:mm"
                value-type="date"
                input-class="form-control mr-2 rounded-right"
                @input="(event) => { finishedFilter.dates = [event[0], event[1]]; }"
              />
              <div
                v-if="finishedFilter.dates.length > 0"
                class="input-group-append"
              >
                <button
                  class="btn btn-light border rounded"
                  @click="finishedFilter.dates = []"
                >
                  <font-awesome-icon
                    class="gray"
                    icon="times"
                  />
                </button>
              </div>
            </div>
            <template
              slot="checkboxTemplate"
              slot-scope="{props}"
            >
              <td :class="props.className">
                <input
                  :checked="kgm_getNestedValue(props.field, props.dataItem)"
                  type="checkbox"
                  disabled
                  readonly
                >
              </td>
            </template>
            <template
              slot="dateTemplate"
              slot-scope="{props}"
            >
              <td :class="props.className">
                <span v-tooltip="dateTime_fromNow(kgm_getNestedValue(props.field, props.dataItem))">{{ dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem)) }}</span>
              </td>
            </template>
            <template
              slot="durationTemplate"
              slot-scope="{props}"
            >
              <td :class="props.className">
                <span v-if="kgm_getNestedValue(props.field, props.dataItem)">{{ numberFilter_twoDecimals(kgm_getNestedValue(props.field, props.dataItem)) }} seconds</span>
              </td>
            </template>
            <template
              slot="optionsTemplate"
              slot-scope="{props}"
            >
              <td :class="props.className">
                <div class="btn-group">
                  <button
                    class="btn btn-primary btn-sm"
                    @click="showSidebar(props.dataItem)"
                  >
                    <font-awesome-icon
                      class="mr-2"
                      icon="info-circle"
                    />
                    <span>Details</span>
                  </button>
                </div>
              </td>
            </template>
          </Grid>
        </template>
      </div>
      <div
        v-if="showTab === 'uploads'"
      >
        <VideoPublisherUpload
          ref="videoPublisherUpload"
          :installation-id="installationId"
          :lane-number="laneNumber"
        />
      </div>
    </div>

    <Sidebar
      :show-sidebar="showSidebarBoolean"
      :sidebar-width="800"
      @close="hideSidebar"
    >
      <VideoPostProcessing
        ref="videoPostProcessing"
        :lane-number="laneNumber"
        @close="hideSidebar"
      />
    </Sidebar>
    <Sidebar
      v-if="showReprocessSidebarBoolean"
      :show-sidebar="showReprocessSidebarBoolean"
      :sidebar-width="600"
      @close="hideReprocessSidebar"
    >
      <VideoPublisherReprocess
        :installation-id="installationId"
        :lane-number="laneNumber"
        @refresh="hideReprocessSidebar"
      />
    </Sidebar>
    <SweetModal
      ref="confirmRestart"
      title="Restart?"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('sureToRestart', {'0' : 'VideoPostProcessing'}) }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmRestart.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-primary float-right mb-3"
        @click="restartVideoPostProcessing()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="redo-alt"
        />{{ 'Restart' }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </Portlet>
</template>

<script>
// import components
import DatePicker from 'vue2-datepicker';
// import mixins
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { numberFilterMixin } from '@/mixins/numberFilterMixin.js';
import numberConversionAdapter from '@/adapter/numberConversionAdapter.js';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: 'VideoPublisher',
  components: {
    Sidebar: () => import('@/components/Base/Sidebar.vue'),
    VideoPostProcessing: () => import('@/components/VideoPublisher/VideoPostProcessing.vue'),
    VideoPublisherUpload: () => import('@/components/VideoPublisher/VideoPublisherUpload.vue'),
    VideoPublisherReprocess: () => import('@/components/VideoPublisher/VideoPublisherReprocess.vue'),
    DatePicker,
    SweetModal
  },
  mixins: [
    dateTimeMixin,
    numberFilterMixin,
    numberConversionAdapter,
    kendoGridMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      finishedFilter: {
        dates: [],
        startDate: null,
        endDate: null
      },
      createdFilter: {
        dates: [],
        startDate: null,
        endDate: null
      },
      createDateShortcuts: [
        {
          text: 'Today',
          onClick: () => {
            let tmpDate = new Date(Date.now());
            this.createdFilter.startDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 1, 0, 0, 0);
            this.createdFilter.endDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), 23, 59, 59);
            this.createdFilter.dates = [];
            this.createdFilter.dates.push(this.createdFilter.startDate, this.createdFilter.endDate);
          }
        },
        {
          text: 'Yesterday',
          onClick: () => {
            let tmpDate = new Date(Date.now());
            this.createdFilter.startDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 2, 0, 0, 0);
            this.createdFilter.endDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 1, 23, 59, 59);
            this.createdFilter.dates = [];
            this.createdFilter.dates.push(this.createdFilter.startDate, this.createdFilter.endDate);
          }
        },
        {
          text: 'The day before yesterday',
          onClick: () => {
            let tmpDate = new Date(Date.now());
            this.createdFilter.startDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 3, 0, 0, 0);
            this.createdFilter.endDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 2, 23, 59, 59);
            this.createdFilter.dates = [];
            this.createdFilter.dates.push(this.createdFilter.startDate, this.createdFilter.endDate);
          }
        }
      ],
      finishedShortcuts: [
        {
          text: 'Today',
          onClick: () => {
            let tmpDate = new Date(Date.now());
            this.finishedFilter.startDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 1, 0, 0, 0);
            this.finishedFilter.endDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), 23, 59, 59);
            this.finishedFilter.dates = [];
            this.finishedFilter.dates.push(this.finishedFilter.startDate, this.finishedFilter.endDate);
          }
        },
        {
          text: 'Yesterday',
          onClick: () => {
            let tmpDate = new Date(Date.now());
            this.finishedFilter.startDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 2, 0, 0, 0);
            this.finishedFilter.endDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 1, 23, 59, 59);
            this.finishedFilter.dates = [];
            this.finishedFilter.dates.push(this.finishedFilter.startDate, this.finishedFilter.endDate);
          }
        },
        {
          text: 'The day before yesterday',
          onClick: () => {
            let tmpDate = new Date(Date.now());
            this.finishedFilter.startDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 3, 0, 0, 0);
            this.finishedFilter.endDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 2, 23, 59, 59);
            this.finishedFilter.dates = [];
            this.finishedFilter.dates.push(this.finishedFilter.startDate, this.finishedFilter.endDate);
          }
        }
      ],
      showTab: 'sessions',
      currentDate: null,
      showReprocessSidebarBoolean: false,
      onlyProcessed: false,
      loadingSessions: true,
      sessions: null,
      sessionCount: 0,
      refreshInterval: null,
      cancelSessionsSource: null,
      showSidebarBoolean: false,
      skipCount: 0,
      takeCount: 0,
      filedModelTypes: {
        createDate: { type: 'date' },
        finishDate: { type: 'date' },
        sessionId: { type: 'string' },
        reprocess: { type: 'bool' },
        isUploaded: { type: 'bool' },
        isProcessed: { type: 'bool' },
        duration: { type: 'number' }
      },
      kgm_sort: [
        {
          field: 'createDate',
          dir: 'desc'
        }
      ],
      kgm_columns: [
        {
          field: 'createDate',
          filterable: true,
          filter: 'date',
          title: 'Created',
          width: '200px',
          cell: 'dateTemplate',
          filterCell: "createdFilter"
        },
        {
          field: 'finishDate',
          filterable: true,
          filter: 'date',
          title: 'Finished',
          width: '200px',
          cell: 'dateTemplate',
          hideOn: ['xlDown'],
          filterCell: "finishedFilter"
        },
        {
          filterable: false,
          title: 'Session Id',
          field: 'sessionId',
        },
        {
          filterable: false,
          title: 'Published',
          field: 'isPublished',
          cell: 'checkboxTemplate',
          width: '120px',
          hideOn: ['xsDown']
        },
        {
          filterable: false,
          title: 'Processed',
          field: 'isProcessed',
          cell: 'checkboxTemplate',
          width: '95px',
          hideOn: ['lgDown']
        },
        {
          filterable: false,
          title: 'Uploaded',
          field: 'isUploaded',
          cell: 'checkboxTemplate',
          width: '120px',
          hideOn: ['mdDown']
        },
        {
          filterable: false,
          title: 'Faulty',
          field: 'isFaulty',
          cell: 'checkboxTemplate',
          width: '65px',
          hideOn: ['xsDown']
        },
        {
          filterable: false,
          title: 'Duration',
          field: 'duration',
          width: '125px',
          cell: 'durationTemplate',
          hideOn: ['xxsDown'],
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
          width: '105px'
        }
      ]
    }
  },
  computed: {
    computedSessions () {
      if (this.sessions == null || this.sessions.length == 0) {
        return null;
      }
      if (this.onlyProcessed == true) {
        let processedSessions = this.sessions.filter((session) => {
          return session.isProcessed == true;
        });
        return processedSessions;
      }
      let processedSessions = this.sessions.filter((session) => {
        return this.isInbetween(session.createDate, this.createdFilter) && this.isInbetween(session.finishDate, this.finishedFilter) == true;
      });
      return processedSessions;
    }
  },
  async created () {
    await this.refreshSessions();
    this.createInterval();
  },
  beforeDestroy () {
    this.cancelInterval();
    this.cancelSessionsRequest();
  },
  mounted () {
    this.skipCount = 0;
    this.takeCount = this.kgm_take;
  },
  methods: {
    openConfirmModal () {
      this.$refs.confirmRestart.open();
    },
    createInterval () {
      this.refreshInterval = window.setInterval(() => {
        this.refreshSessions();
      }, 10000);
    },
    cancelInterval () {
      window.clearInterval(this.refreshInterval);
      this.refreshInterval = null;
      this.loadingUploads = false;
    },
    cancelSessionsRequest () {
      if (this.cancelSessionsSource) {
        this.cancelSessionsSource.cancel();
      }      
    },
    createCancellationToken () {
      this.cancelSessionsSource = this.axios.CancelToken.source();
    },
    // eslint-disable-next-line
    isInbetween(session, filter) {
      let dateFrom = filter.dates[0] || filter.startDate;
      let dateTo = filter.dates[1] || filter.startDate;
      let dateCheck = session;
      
      if ((dateCheck == null || dateTo == null || dateFrom == null || filter == null || filter.dates == null || filter.dates.length == 0) == true) {
        return true;
      }
      
      if ((dateCheck.getTime() <= dateTo.getTime() && dateCheck.getTime() >= dateFrom.getTime())) {
        return true;
      }
      return false;
    },
    refreshVideoPublisher () {
      if(this.$refs.videoPublisherUpload)
      {
        this.$refs.videoPublisherUpload.getData();
      }
      this.refreshSessions();
    },
    async refreshSessions () {
      this.cancelSessionsRequest();
      this.createCancellationToken();
        
      await this.getSessionCount();
      await this.getSessions();
    },

//#region Sidebar
    showSidebar (emitValues) {
      this.showSidebarBoolean = true;
      this.$refs.videoPostProcessing.loading = true;
      this.$refs.videoPostProcessing.loadData(this.installationId, emitValues);
    },
    hideSidebar () {
      this.showSidebarBoolean = false;
      if(this.$refs.videoPostProcessing)
      {
        this.$refs.videoPostProcessing.clearData();
      }
      this.publisherSession = null;
    },
    showReprocessSidebar () {
      this.showReprocessSidebarBoolean = true;
    },
    hideReprocessSidebar () {
      this.refreshVideoPublisher();
      this.showReprocessSidebarBoolean = false;
    },
//#endregion

//#region API-calls
    async getSessionCount () {
      let requestConfig = { cancelToken: this.cancelSessionsSource.token };
      let url = `/VideoPublisher/GetSessionCount/${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `?laneNumber=${ this.laneNumber }`;
      }
      await this.axios.get(url, requestConfig)
        .then((sessionResponse) => {
          if (sessionResponse == null) {
            return;
          }
          if (sessionResponse.data == null) {
            return;
          }
          this.sessionCount = sessionResponse.data;
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        });
    },
    async getSessions () {
      let requestConfig = { cancelToken: this.cancelSessionsSource.token };
      let url = `/VideoPublisher/GetSessions/${ this.installationId }/${ this.skipCount }/${ this.takeCount }`; 
      if (this.laneNumber)
      {
        url += `?laneNumber=${ this.laneNumber }`;
      }
      await this.axios.get(url, requestConfig)
        .then((sessionResponse) => {
          if (sessionResponse == null) {
            return;
          }
          if (sessionResponse.data == null) {
            return;
          }
          this.sessions = sessionResponse.data;
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        })
        .finally(() => {
          this.loadingSessions = false;
        });
    },
    loadAllSessionsToCache () {
      this.loadingSessions = true;
      let requestConfig = { cancelToken: this.cancelSessionsSource.token };
      let url = `/VideoPublisher/AddSessionsToCache/${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `?laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url, requestConfig)
        .then(() => {
          this.loadingSessions = false;
        })
        .catch(() => {
          this.loadingSessions = false;
          if (this.axios.isCancel()) {
            return;
          }
        });
    },
    restartVideoPostProcessing () {
      this.$refs.confirmRestart.close();
      this.axios.get(`/Endpoint/GetTrackerDevice?installationId=${this.installationId}&componentType=VideoPostProcessing&laneNumber=${this.laneNumber}`)
        .then((response) => {
          if (response) {
            let commandRequest = {
              'Command': 'restartVideoPostProcessing',
              'Args': ''
            };
            if (response.data && response.data.uuid)
            {
              this.axios.post(`/CentralDeviceManagement/ExecuteCommand?deviceUuid=${ response.data.uuid }`, commandRequest)
                .then((response) => {
                  this.$snotify.info(response.data.output);
                })
                .catch(() => {
                   this.$snotify.error("Can not restart VideoPostProcessing");
                });
            }
        }
        });
    },
//#endregion

//#region Kendo grids
    gridPageChange (event) {
      this.skipCount = event.page.skip;
      this.takeCount = event.page.take;

      this.getSessions();
    }
//#endregion
  }
}
</script>

<style scoped>
.nav-link {
  cursor: pointer
}
</style>

<style>
.videoPublisher .mx-datepicker.mx-datepicker-range {
  height: 100%;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  margin-right: 0.5rem;
}

.videoPublisher .k-grid-header-wrap,
.videoPublisher .k-grid-header-wrap table,
.videoPublisher .k-grid-header-wrap table thead,
.videoPublisher .k-grid-header-wrap table thead .k-filter-row,
.videoPublisher .k-grid-header-wrap table thead .k-filter-row th {
  overflow: visible;
}

.videoPublisher .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}

.videoPublisher .m-portlet__body {
  min-height: 144px !important;
}
.k-animation-container {
  max-width: 500px !important;
  min-width: 300px !important;
  width: 500px !important;
}
.videoPublisher .tableScrollContainer {
  overflow-y: scroll;
  max-height: 300px;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.videoPublisher .tableScrollContainer table {
  border: 0;
}
.videoPublisher .latestSessionsOnly {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
